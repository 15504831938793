import { default as energy_45green_45practicesqCohM5kCNnMeta } from "/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/about-us/energy-green-practices.vue?macro=true";
import { default as fun_45part_45journeyK3FY3UYtgdMeta } from "/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/about-us/fun-part-journey.vue?macro=true";
import { default as homesafe_45colorado_45master_45builderW43f6esa4eMeta } from "/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/about-us/homesafe-colorado-master-builder.vue?macro=true";
import { default as indexwiBsq5GLkNMeta } from "/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/about-us/index.vue?macro=true";
import { default as our_45team8Yie8eny6NMeta } from "/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/about-us/our-team.vue?macro=true";
import { default as preferred_45lenderaR2JsHHcisMeta } from "/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/about-us/preferred-lender.vue?macro=true";
import { default as realtor_45resourcesZNuVhhgjcoMeta } from "/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/about-us/realtor-resources.vue?macro=true";
import { default as _91post_938MiPoiVpvvMeta } from "/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/blog/[post].vue?macro=true";
import { default as indexvzihGVfQdFMeta } from "/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/blog/category/[category]/index.vue?macro=true";
import { default as indexNWY1fOafvtMeta } from "/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/blog/index.vue?macro=true";
import { default as _91_46_46_46slug_93h506LLZ71CMeta } from "/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/campaigns/[...slug].vue?macro=true";
import { default as index7zMOFaTIM4Meta } from "/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/campaigns/index.vue?macro=true";
import { default as central_45park_45special_45offer9f1142XmFYMeta } from "/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/central-park-special-offer.vue?macro=true";
import { default as ask_45a_45questionBfE6X02oQMMeta } from "/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/contact-us/ask-a-question.vue?macro=true";
import { default as career_45opportunitiesXhQYJLFwTZMeta } from "/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/contact-us/career-opportunities.vue?macro=true";
import { default as faqgZ7dzM0QJSMeta } from "/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/contact-us/faq.vue?macro=true";
import { default as homeowner_45resourcesQAPncOc3jkMeta } from "/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/contact-us/homeowner-resources.vue?macro=true";
import { default as indexYLSnyn0Z6WMeta } from "/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/contact-us/index.vue?macro=true";
import { default as our_45locationsBnBW4Dd7AiMeta } from "/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/contact-us/our-locations.vue?macro=true";
import { default as current_45offersH8TAPDyjm6Meta } from "/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/current-offers.vue?macro=true";
import { default as indexxvTQ4peRSYMeta } from "/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/events/[event]/index.vue?macro=true";
import { default as indexbkbqy6DjXtMeta } from "/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/events/index.vue?macro=true";
import { default as indexroHAbuqB4TMeta } from "/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/index.vue?macro=true";
import { default as index0OY8T20GFMMeta } from "/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/models/[model]/index.vue?macro=true";
import { default as indexh85QVLhI89Meta } from "/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/plans/[plan]/index.vue?macro=true";
import { default as indexLcsFZOXjOQMeta } from "/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/qmi/[home]/index.vue?macro=true";
import { default as indexoBM2j5r59rMeta } from "/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/index.vue?macro=true";
import { default as knolls_45hoa_45informationK1HnFpKzYyMeta } from "/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/knolls-hoa-information.vue?macro=true";
import { default as land_45acquisitionzzGiieczapMeta } from "/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/land-acquisition.vue?macro=true";
import { default as brents_45placekNOFLw7hdeMeta } from "/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/lifestyle/brents-place.vue?macro=true";
import { default as experience_45lower_45maintenanceb0CB3l9GtLMeta } from "/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/lifestyle/experience-lower-maintenance.vue?macro=true";
import { default as _91testimonial_93TFs7882fKZMeta } from "/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/[testimonial].vue?macro=true";
import { default as indexj3nTucVWmlMeta } from "/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/category/[category]/index.vue?macro=true";
import { default as indexyfTav6l9fWMeta } from "/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/index.vue?macro=true";
import { default as whats_45lifefullnessE7RDM8dTTKMeta } from "/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/lifestyle/whats-lifefullness.vue?macro=true";
import { default as _91_46_46_46slug_93xPuK8RyhqfMeta } from "/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/marshallfire/[...slug].vue?macro=true";
import { default as my_45favorites85HxnQNm5TMeta } from "/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/my-favorites.vue?macro=true";
import { default as new_45home_45searchpCHJSkpMl0Meta } from "/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/new-home-search.vue?macro=true";
import { default as easyhousewFUIYn8fb7Meta } from "/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/easyhouse.vue?macro=true";
import { default as limitededitionUWnD05wt3VMeta } from "/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/limitededition.vue?macro=true";
import { default as wee_45cottageKhWwYxArMFMeta } from "/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/wee-cottage.vue?macro=true";
import { default as indexztgp7UBZtAMeta } from "/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/our-homes/index.vue?macro=true";
import { default as our_45neighborhoodsPXR1S7e8yOMeta } from "/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/our-neighborhoods.vue?macro=true";
import { default as partner_45with_45usLamTXCLjAuMeta } from "/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/partner-with-us.vue?macro=true";
import { default as privacytermsElRWHZDkv7Meta } from "/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/privacyterms.vue?macro=true";
import { default as sandboxnYw2R332xbMeta } from "/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/sandbox.vue?macro=true";
import { default as _91_46_46_46slug_93gajDkKwjk2Meta } from "/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/videos/[...slug].vue?macro=true";
import { default as component_45stub75NTVOuqo4Meta } from "/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
import { default as component_45stub75NTVOuqo4 } from "/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/node_modules/nuxt/dist/pages/runtime/component-stub";
export default [
  {
    name: energy_45green_45practicesqCohM5kCNnMeta?.name ?? "about-us-energy-green-practices",
    path: energy_45green_45practicesqCohM5kCNnMeta?.path ?? "/about-us/energy-green-practices",
    meta: energy_45green_45practicesqCohM5kCNnMeta || {},
    alias: energy_45green_45practicesqCohM5kCNnMeta?.alias || [],
    redirect: energy_45green_45practicesqCohM5kCNnMeta?.redirect,
    component: () => import("/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/about-us/energy-green-practices.vue").then(m => m.default || m)
  },
  {
    name: fun_45part_45journeyK3FY3UYtgdMeta?.name ?? "about-us-fun-part-journey",
    path: fun_45part_45journeyK3FY3UYtgdMeta?.path ?? "/about-us/fun-part-journey",
    meta: fun_45part_45journeyK3FY3UYtgdMeta || {},
    alias: fun_45part_45journeyK3FY3UYtgdMeta?.alias || [],
    redirect: fun_45part_45journeyK3FY3UYtgdMeta?.redirect,
    component: () => import("/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/about-us/fun-part-journey.vue").then(m => m.default || m)
  },
  {
    name: homesafe_45colorado_45master_45builderW43f6esa4eMeta?.name ?? "about-us-homesafe-colorado-master-builder",
    path: homesafe_45colorado_45master_45builderW43f6esa4eMeta?.path ?? "/about-us/homesafe-colorado-master-builder",
    meta: homesafe_45colorado_45master_45builderW43f6esa4eMeta || {},
    alias: homesafe_45colorado_45master_45builderW43f6esa4eMeta?.alias || [],
    redirect: homesafe_45colorado_45master_45builderW43f6esa4eMeta?.redirect,
    component: () => import("/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/about-us/homesafe-colorado-master-builder.vue").then(m => m.default || m)
  },
  {
    name: indexwiBsq5GLkNMeta?.name ?? "about-us",
    path: indexwiBsq5GLkNMeta?.path ?? "/about-us",
    meta: indexwiBsq5GLkNMeta || {},
    alias: indexwiBsq5GLkNMeta?.alias || [],
    redirect: indexwiBsq5GLkNMeta?.redirect,
    component: () => import("/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: our_45team8Yie8eny6NMeta?.name ?? "about-us-our-team",
    path: our_45team8Yie8eny6NMeta?.path ?? "/about-us/our-team",
    meta: our_45team8Yie8eny6NMeta || {},
    alias: our_45team8Yie8eny6NMeta?.alias || [],
    redirect: our_45team8Yie8eny6NMeta?.redirect,
    component: () => import("/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/about-us/our-team.vue").then(m => m.default || m)
  },
  {
    name: preferred_45lenderaR2JsHHcisMeta?.name ?? "about-us-preferred-lender",
    path: preferred_45lenderaR2JsHHcisMeta?.path ?? "/about-us/preferred-lender",
    meta: preferred_45lenderaR2JsHHcisMeta || {},
    alias: preferred_45lenderaR2JsHHcisMeta?.alias || [],
    redirect: preferred_45lenderaR2JsHHcisMeta?.redirect,
    component: () => import("/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/about-us/preferred-lender.vue").then(m => m.default || m)
  },
  {
    name: realtor_45resourcesZNuVhhgjcoMeta?.name ?? "about-us-realtor-resources",
    path: realtor_45resourcesZNuVhhgjcoMeta?.path ?? "/about-us/realtor-resources",
    meta: realtor_45resourcesZNuVhhgjcoMeta || {},
    alias: realtor_45resourcesZNuVhhgjcoMeta?.alias || [],
    redirect: realtor_45resourcesZNuVhhgjcoMeta?.redirect,
    component: () => import("/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/about-us/realtor-resources.vue").then(m => m.default || m)
  },
  {
    name: _91post_938MiPoiVpvvMeta?.name ?? "blog-post",
    path: _91post_938MiPoiVpvvMeta?.path ?? "/blog/:post()",
    meta: _91post_938MiPoiVpvvMeta || {},
    alias: _91post_938MiPoiVpvvMeta?.alias || [],
    redirect: _91post_938MiPoiVpvvMeta?.redirect,
    component: () => import("/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/blog/[post].vue").then(m => m.default || m)
  },
  {
    name: indexvzihGVfQdFMeta?.name ?? "blog-category-category",
    path: indexvzihGVfQdFMeta?.path ?? "/blog/category/:category()",
    meta: indexvzihGVfQdFMeta || {},
    alias: indexvzihGVfQdFMeta?.alias || [],
    redirect: indexvzihGVfQdFMeta?.redirect,
    component: () => import("/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/blog/category/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: indexNWY1fOafvtMeta?.name ?? "blog",
    path: indexNWY1fOafvtMeta?.path ?? "/blog",
    meta: indexNWY1fOafvtMeta || {},
    alias: indexNWY1fOafvtMeta?.alias || [],
    redirect: indexNWY1fOafvtMeta?.redirect,
    component: () => import("/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93h506LLZ71CMeta?.name ?? "campaigns-slug",
    path: _91_46_46_46slug_93h506LLZ71CMeta?.path ?? "/campaigns/:slug(.*)*",
    meta: _91_46_46_46slug_93h506LLZ71CMeta || {},
    alias: _91_46_46_46slug_93h506LLZ71CMeta?.alias || [],
    redirect: _91_46_46_46slug_93h506LLZ71CMeta?.redirect,
    component: () => import("/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/campaigns/[...slug].vue").then(m => m.default || m)
  },
  {
    name: index7zMOFaTIM4Meta?.name ?? "campaigns",
    path: index7zMOFaTIM4Meta?.path ?? "/campaigns",
    meta: index7zMOFaTIM4Meta || {},
    alias: index7zMOFaTIM4Meta?.alias || [],
    redirect: index7zMOFaTIM4Meta?.redirect,
    component: () => import("/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/campaigns/index.vue").then(m => m.default || m)
  },
  {
    name: central_45park_45special_45offer9f1142XmFYMeta?.name ?? "central-park-special-offer",
    path: central_45park_45special_45offer9f1142XmFYMeta?.path ?? "/central-park-special-offer",
    meta: central_45park_45special_45offer9f1142XmFYMeta || {},
    alias: central_45park_45special_45offer9f1142XmFYMeta?.alias || [],
    redirect: central_45park_45special_45offer9f1142XmFYMeta?.redirect,
    component: () => import("/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/central-park-special-offer.vue").then(m => m.default || m)
  },
  {
    name: ask_45a_45questionBfE6X02oQMMeta?.name ?? "contact-us-ask-a-question",
    path: ask_45a_45questionBfE6X02oQMMeta?.path ?? "/contact-us/ask-a-question",
    meta: ask_45a_45questionBfE6X02oQMMeta || {},
    alias: ask_45a_45questionBfE6X02oQMMeta?.alias || [],
    redirect: ask_45a_45questionBfE6X02oQMMeta?.redirect,
    component: () => import("/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/contact-us/ask-a-question.vue").then(m => m.default || m)
  },
  {
    name: career_45opportunitiesXhQYJLFwTZMeta?.name ?? "contact-us-career-opportunities",
    path: career_45opportunitiesXhQYJLFwTZMeta?.path ?? "/contact-us/career-opportunities",
    meta: career_45opportunitiesXhQYJLFwTZMeta || {},
    alias: career_45opportunitiesXhQYJLFwTZMeta?.alias || [],
    redirect: career_45opportunitiesXhQYJLFwTZMeta?.redirect,
    component: () => import("/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/contact-us/career-opportunities.vue").then(m => m.default || m)
  },
  {
    name: faqgZ7dzM0QJSMeta?.name ?? "contact-us-faq",
    path: faqgZ7dzM0QJSMeta?.path ?? "/contact-us/faq",
    meta: faqgZ7dzM0QJSMeta || {},
    alias: faqgZ7dzM0QJSMeta?.alias || [],
    redirect: faqgZ7dzM0QJSMeta?.redirect,
    component: () => import("/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/contact-us/faq.vue").then(m => m.default || m)
  },
  {
    name: homeowner_45resourcesQAPncOc3jkMeta?.name ?? "contact-us-homeowner-resources",
    path: homeowner_45resourcesQAPncOc3jkMeta?.path ?? "/contact-us/homeowner-resources",
    meta: homeowner_45resourcesQAPncOc3jkMeta || {},
    alias: homeowner_45resourcesQAPncOc3jkMeta?.alias || [],
    redirect: homeowner_45resourcesQAPncOc3jkMeta?.redirect,
    component: () => import("/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/contact-us/homeowner-resources.vue").then(m => m.default || m)
  },
  {
    name: indexYLSnyn0Z6WMeta?.name ?? "contact-us",
    path: indexYLSnyn0Z6WMeta?.path ?? "/contact-us",
    meta: indexYLSnyn0Z6WMeta || {},
    alias: indexYLSnyn0Z6WMeta?.alias || [],
    redirect: indexYLSnyn0Z6WMeta?.redirect,
    component: () => import("/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/contact-us/index.vue").then(m => m.default || m)
  },
  {
    name: our_45locationsBnBW4Dd7AiMeta?.name ?? "contact-us-our-locations",
    path: our_45locationsBnBW4Dd7AiMeta?.path ?? "/contact-us/our-locations",
    meta: our_45locationsBnBW4Dd7AiMeta || {},
    alias: our_45locationsBnBW4Dd7AiMeta?.alias || [],
    redirect: our_45locationsBnBW4Dd7AiMeta?.redirect,
    component: () => import("/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/contact-us/our-locations.vue").then(m => m.default || m)
  },
  {
    name: current_45offersH8TAPDyjm6Meta?.name ?? "current-offers",
    path: current_45offersH8TAPDyjm6Meta?.path ?? "/current-offers",
    meta: current_45offersH8TAPDyjm6Meta || {},
    alias: current_45offersH8TAPDyjm6Meta?.alias || [],
    redirect: current_45offersH8TAPDyjm6Meta?.redirect,
    component: () => import("/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/current-offers.vue").then(m => m.default || m)
  },
  {
    name: indexxvTQ4peRSYMeta?.name ?? "events-event",
    path: indexxvTQ4peRSYMeta?.path ?? "/events/:event()",
    meta: indexxvTQ4peRSYMeta || {},
    alias: indexxvTQ4peRSYMeta?.alias || [],
    redirect: indexxvTQ4peRSYMeta?.redirect,
    component: () => import("/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/events/[event]/index.vue").then(m => m.default || m)
  },
  {
    name: indexbkbqy6DjXtMeta?.name ?? "events",
    path: indexbkbqy6DjXtMeta?.path ?? "/events",
    meta: indexbkbqy6DjXtMeta || {},
    alias: indexbkbqy6DjXtMeta?.alias || [],
    redirect: indexbkbqy6DjXtMeta?.redirect,
    component: () => import("/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexroHAbuqB4TMeta?.name ?? "homes-location-community",
    path: indexroHAbuqB4TMeta?.path ?? "/homes/:location()/:community()",
    meta: indexroHAbuqB4TMeta || {},
    alias: indexroHAbuqB4TMeta?.alias || [],
    redirect: indexroHAbuqB4TMeta?.redirect,
    component: () => import("/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/index.vue").then(m => m.default || m)
  },
  {
    name: index0OY8T20GFMMeta?.name ?? "homes-location-community-models-model",
    path: index0OY8T20GFMMeta?.path ?? "/homes/:location()/:community()/models/:model()",
    meta: index0OY8T20GFMMeta || {},
    alias: index0OY8T20GFMMeta?.alias || [],
    redirect: index0OY8T20GFMMeta?.redirect,
    component: () => import("/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/models/[model]/index.vue").then(m => m.default || m)
  },
  {
    name: indexh85QVLhI89Meta?.name ?? "homes-location-community-plans-plan",
    path: indexh85QVLhI89Meta?.path ?? "/homes/:location()/:community()/plans/:plan()",
    meta: indexh85QVLhI89Meta || {},
    alias: indexh85QVLhI89Meta?.alias || [],
    redirect: indexh85QVLhI89Meta?.redirect,
    component: () => import("/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/plans/[plan]/index.vue").then(m => m.default || m)
  },
  {
    name: indexLcsFZOXjOQMeta?.name ?? "homes-location-community-qmi-home",
    path: indexLcsFZOXjOQMeta?.path ?? "/homes/:location()/:community()/qmi/:home()",
    meta: indexLcsFZOXjOQMeta || {},
    alias: indexLcsFZOXjOQMeta?.alias || [],
    redirect: indexLcsFZOXjOQMeta?.redirect,
    component: () => import("/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/qmi/[home]/index.vue").then(m => m.default || m)
  },
  {
    name: indexoBM2j5r59rMeta?.name ?? "index",
    path: indexoBM2j5r59rMeta?.path ?? "/",
    meta: indexoBM2j5r59rMeta || {},
    alias: indexoBM2j5r59rMeta?.alias || [],
    redirect: indexoBM2j5r59rMeta?.redirect,
    component: () => import("/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/index.vue").then(m => m.default || m)
  },
  {
    name: knolls_45hoa_45informationK1HnFpKzYyMeta?.name ?? "knolls-hoa-information",
    path: knolls_45hoa_45informationK1HnFpKzYyMeta?.path ?? "/knolls-hoa-information",
    meta: knolls_45hoa_45informationK1HnFpKzYyMeta || {},
    alias: knolls_45hoa_45informationK1HnFpKzYyMeta?.alias || [],
    redirect: knolls_45hoa_45informationK1HnFpKzYyMeta?.redirect,
    component: () => import("/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/knolls-hoa-information.vue").then(m => m.default || m)
  },
  {
    name: land_45acquisitionzzGiieczapMeta?.name ?? "land-acquisition",
    path: land_45acquisitionzzGiieczapMeta?.path ?? "/land-acquisition",
    meta: land_45acquisitionzzGiieczapMeta || {},
    alias: land_45acquisitionzzGiieczapMeta?.alias || [],
    redirect: land_45acquisitionzzGiieczapMeta?.redirect,
    component: () => import("/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/land-acquisition.vue").then(m => m.default || m)
  },
  {
    name: brents_45placekNOFLw7hdeMeta?.name ?? "lifestyle-brents-place",
    path: brents_45placekNOFLw7hdeMeta?.path ?? "/lifestyle/brents-place",
    meta: brents_45placekNOFLw7hdeMeta || {},
    alias: brents_45placekNOFLw7hdeMeta?.alias || [],
    redirect: brents_45placekNOFLw7hdeMeta?.redirect,
    component: () => import("/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/lifestyle/brents-place.vue").then(m => m.default || m)
  },
  {
    name: experience_45lower_45maintenanceb0CB3l9GtLMeta?.name ?? "lifestyle-experience-lower-maintenance",
    path: experience_45lower_45maintenanceb0CB3l9GtLMeta?.path ?? "/lifestyle/experience-lower-maintenance",
    meta: experience_45lower_45maintenanceb0CB3l9GtLMeta || {},
    alias: experience_45lower_45maintenanceb0CB3l9GtLMeta?.alias || [],
    redirect: experience_45lower_45maintenanceb0CB3l9GtLMeta?.redirect,
    component: () => import("/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/lifestyle/experience-lower-maintenance.vue").then(m => m.default || m)
  },
  {
    name: _91testimonial_93TFs7882fKZMeta?.name ?? "lifestyle-reviews-testimonial",
    path: _91testimonial_93TFs7882fKZMeta?.path ?? "/lifestyle/reviews/:testimonial()",
    meta: _91testimonial_93TFs7882fKZMeta || {},
    alias: _91testimonial_93TFs7882fKZMeta?.alias || [],
    redirect: _91testimonial_93TFs7882fKZMeta?.redirect,
    component: () => import("/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/[testimonial].vue").then(m => m.default || m)
  },
  {
    name: indexj3nTucVWmlMeta?.name ?? "lifestyle-reviews-category-category",
    path: indexj3nTucVWmlMeta?.path ?? "/lifestyle/reviews/category/:category()",
    meta: indexj3nTucVWmlMeta || {},
    alias: indexj3nTucVWmlMeta?.alias || [],
    redirect: indexj3nTucVWmlMeta?.redirect,
    component: () => import("/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/category/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: indexyfTav6l9fWMeta?.name ?? "lifestyle-reviews",
    path: indexyfTav6l9fWMeta?.path ?? "/lifestyle/reviews",
    meta: indexyfTav6l9fWMeta || {},
    alias: indexyfTav6l9fWMeta?.alias || [],
    redirect: indexyfTav6l9fWMeta?.redirect,
    component: () => import("/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: whats_45lifefullnessE7RDM8dTTKMeta?.name ?? "lifestyle-whats-lifefullness",
    path: whats_45lifefullnessE7RDM8dTTKMeta?.path ?? "/lifestyle/whats-lifefullness",
    meta: whats_45lifefullnessE7RDM8dTTKMeta || {},
    alias: whats_45lifefullnessE7RDM8dTTKMeta?.alias || [],
    redirect: whats_45lifefullnessE7RDM8dTTKMeta?.redirect,
    component: () => import("/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/lifestyle/whats-lifefullness.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93xPuK8RyhqfMeta?.name ?? "marshallfire-slug",
    path: _91_46_46_46slug_93xPuK8RyhqfMeta?.path ?? "/marshallfire/:slug(.*)*",
    meta: _91_46_46_46slug_93xPuK8RyhqfMeta || {},
    alias: _91_46_46_46slug_93xPuK8RyhqfMeta?.alias || [],
    redirect: _91_46_46_46slug_93xPuK8RyhqfMeta?.redirect,
    component: () => import("/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/marshallfire/[...slug].vue").then(m => m.default || m)
  },
  {
    name: my_45favorites85HxnQNm5TMeta?.name ?? "my-favorites",
    path: my_45favorites85HxnQNm5TMeta?.path ?? "/my-favorites",
    meta: my_45favorites85HxnQNm5TMeta || {},
    alias: my_45favorites85HxnQNm5TMeta?.alias || [],
    redirect: my_45favorites85HxnQNm5TMeta?.redirect,
    component: () => import("/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/my-favorites.vue").then(m => m.default || m)
  },
  {
    name: new_45home_45searchpCHJSkpMl0Meta?.name ?? "new-home-search",
    path: new_45home_45searchpCHJSkpMl0Meta?.path ?? "/new-home-search",
    meta: new_45home_45searchpCHJSkpMl0Meta || {},
    alias: new_45home_45searchpCHJSkpMl0Meta?.alias || [],
    redirect: new_45home_45searchpCHJSkpMl0Meta?.redirect,
    component: () => import("/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/new-home-search.vue").then(m => m.default || m)
  },
  {
    name: easyhousewFUIYn8fb7Meta?.name ?? "our-homes-home-collections-easyhouse",
    path: easyhousewFUIYn8fb7Meta?.path ?? "/our-homes/home-collections/easyhouse",
    meta: easyhousewFUIYn8fb7Meta || {},
    alias: easyhousewFUIYn8fb7Meta?.alias || [],
    redirect: easyhousewFUIYn8fb7Meta?.redirect,
    component: () => import("/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/easyhouse.vue").then(m => m.default || m)
  },
  {
    name: limitededitionUWnD05wt3VMeta?.name ?? "our-homes-home-collections-limitededition",
    path: limitededitionUWnD05wt3VMeta?.path ?? "/our-homes/home-collections/limitededition",
    meta: limitededitionUWnD05wt3VMeta || {},
    alias: limitededitionUWnD05wt3VMeta?.alias || [],
    redirect: limitededitionUWnD05wt3VMeta?.redirect,
    component: () => import("/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/limitededition.vue").then(m => m.default || m)
  },
  {
    name: wee_45cottageKhWwYxArMFMeta?.name ?? "our-homes-home-collections-wee-cottage",
    path: wee_45cottageKhWwYxArMFMeta?.path ?? "/our-homes/home-collections/wee-cottage",
    meta: wee_45cottageKhWwYxArMFMeta || {},
    alias: wee_45cottageKhWwYxArMFMeta?.alias || [],
    redirect: wee_45cottageKhWwYxArMFMeta?.redirect,
    component: () => import("/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/wee-cottage.vue").then(m => m.default || m)
  },
  {
    name: indexztgp7UBZtAMeta?.name ?? "our-homes",
    path: indexztgp7UBZtAMeta?.path ?? "/our-homes",
    meta: indexztgp7UBZtAMeta || {},
    alias: indexztgp7UBZtAMeta?.alias || [],
    redirect: indexztgp7UBZtAMeta?.redirect,
    component: () => import("/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/our-homes/index.vue").then(m => m.default || m)
  },
  {
    name: our_45neighborhoodsPXR1S7e8yOMeta?.name ?? "our-neighborhoods",
    path: our_45neighborhoodsPXR1S7e8yOMeta?.path ?? "/our-neighborhoods",
    meta: our_45neighborhoodsPXR1S7e8yOMeta || {},
    alias: our_45neighborhoodsPXR1S7e8yOMeta?.alias || [],
    redirect: our_45neighborhoodsPXR1S7e8yOMeta?.redirect,
    component: () => import("/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/our-neighborhoods.vue").then(m => m.default || m)
  },
  {
    name: partner_45with_45usLamTXCLjAuMeta?.name ?? "partner-with-us",
    path: partner_45with_45usLamTXCLjAuMeta?.path ?? "/partner-with-us",
    meta: partner_45with_45usLamTXCLjAuMeta || {},
    alias: partner_45with_45usLamTXCLjAuMeta?.alias || [],
    redirect: partner_45with_45usLamTXCLjAuMeta?.redirect,
    component: () => import("/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/partner-with-us.vue").then(m => m.default || m)
  },
  {
    name: privacytermsElRWHZDkv7Meta?.name ?? "privacyterms",
    path: privacytermsElRWHZDkv7Meta?.path ?? "/privacyterms",
    meta: privacytermsElRWHZDkv7Meta || {},
    alias: privacytermsElRWHZDkv7Meta?.alias || [],
    redirect: privacytermsElRWHZDkv7Meta?.redirect,
    component: () => import("/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/privacyterms.vue").then(m => m.default || m)
  },
  {
    name: sandboxnYw2R332xbMeta?.name ?? "sandbox",
    path: sandboxnYw2R332xbMeta?.path ?? "/sandbox",
    meta: sandboxnYw2R332xbMeta || {},
    alias: sandboxnYw2R332xbMeta?.alias || [],
    redirect: sandboxnYw2R332xbMeta?.redirect,
    component: () => import("/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/sandbox.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93gajDkKwjk2Meta?.name ?? "videos-slug",
    path: _91_46_46_46slug_93gajDkKwjk2Meta?.path ?? "/videos/:slug(.*)*",
    meta: _91_46_46_46slug_93gajDkKwjk2Meta || {},
    alias: _91_46_46_46slug_93gajDkKwjk2Meta?.alias || [],
    redirect: _91_46_46_46slug_93gajDkKwjk2Meta?.redirect,
    component: () => import("/codebuild/output/src2197700027/src/livebcn-headless/nuxtapp/pages/videos/[...slug].vue").then(m => m.default || m)
  },
  {
    name: component_45stub75NTVOuqo4Meta?.name ?? undefined,
    path: component_45stub75NTVOuqo4Meta?.path ?? "/about-us/get-our-brochure/",
    meta: component_45stub75NTVOuqo4Meta || {},
    alias: component_45stub75NTVOuqo4Meta?.alias || [],
    redirect: component_45stub75NTVOuqo4Meta?.redirect,
    component: component_45stub75NTVOuqo4
  },
  {
    name: component_45stub75NTVOuqo4Meta?.name ?? undefined,
    path: component_45stub75NTVOuqo4Meta?.path ?? "/about-us/in-the-news/",
    meta: component_45stub75NTVOuqo4Meta || {},
    alias: component_45stub75NTVOuqo4Meta?.alias || [],
    redirect: component_45stub75NTVOuqo4Meta?.redirect,
    component: component_45stub75NTVOuqo4
  },
  {
    name: component_45stub75NTVOuqo4Meta?.name ?? undefined,
    path: component_45stub75NTVOuqo4Meta?.path ?? "/about-us/realtor-program/",
    meta: component_45stub75NTVOuqo4Meta || {},
    alias: component_45stub75NTVOuqo4Meta?.alias || [],
    redirect: component_45stub75NTVOuqo4Meta?.redirect,
    component: component_45stub75NTVOuqo4
  },
  {
    name: component_45stub75NTVOuqo4Meta?.name ?? undefined,
    path: component_45stub75NTVOuqo4Meta?.path ?? "/about-us/what-we-do/",
    meta: component_45stub75NTVOuqo4Meta || {},
    alias: component_45stub75NTVOuqo4Meta?.alias || [],
    redirect: component_45stub75NTVOuqo4Meta?.redirect,
    component: component_45stub75NTVOuqo4
  },
  {
    name: component_45stub75NTVOuqo4Meta?.name ?? undefined,
    path: component_45stub75NTVOuqo4Meta?.path ?? "/about-us/who-we-are/",
    meta: component_45stub75NTVOuqo4Meta || {},
    alias: component_45stub75NTVOuqo4Meta?.alias || [],
    redirect: component_45stub75NTVOuqo4Meta?.redirect,
    component: component_45stub75NTVOuqo4
  },
  {
    name: component_45stub75NTVOuqo4Meta?.name ?? undefined,
    path: component_45stub75NTVOuqo4Meta?.path ?? "/build-with-us/",
    meta: component_45stub75NTVOuqo4Meta || {},
    alias: component_45stub75NTVOuqo4Meta?.alias || [],
    redirect: component_45stub75NTVOuqo4Meta?.redirect,
    component: component_45stub75NTVOuqo4
  },
  {
    name: component_45stub75NTVOuqo4Meta?.name ?? undefined,
    path: component_45stub75NTVOuqo4Meta?.path ?? "/career-opportunities/",
    meta: component_45stub75NTVOuqo4Meta || {},
    alias: component_45stub75NTVOuqo4Meta?.alias || [],
    redirect: component_45stub75NTVOuqo4Meta?.redirect,
    component: component_45stub75NTVOuqo4
  },
  {
    name: component_45stub75NTVOuqo4Meta?.name ?? undefined,
    path: component_45stub75NTVOuqo4Meta?.path ?? "/career-opportunities/administrative-assistant-2/",
    meta: component_45stub75NTVOuqo4Meta || {},
    alias: component_45stub75NTVOuqo4Meta?.alias || [],
    redirect: component_45stub75NTVOuqo4Meta?.redirect,
    component: component_45stub75NTVOuqo4
  },
  {
    name: component_45stub75NTVOuqo4Meta?.name ?? undefined,
    path: component_45stub75NTVOuqo4Meta?.path ?? "/career-opportunities/director-of-warranty-services/",
    meta: component_45stub75NTVOuqo4Meta || {},
    alias: component_45stub75NTVOuqo4Meta?.alias || [],
    redirect: component_45stub75NTVOuqo4Meta?.redirect,
    component: component_45stub75NTVOuqo4
  },
  {
    name: component_45stub75NTVOuqo4Meta?.name ?? undefined,
    path: component_45stub75NTVOuqo4Meta?.path ?? "/career-opportunities/land-development-entitlements-coordinator/",
    meta: component_45stub75NTVOuqo4Meta || {},
    alias: component_45stub75NTVOuqo4Meta?.alias || [],
    redirect: component_45stub75NTVOuqo4Meta?.redirect,
    component: component_45stub75NTVOuqo4
  },
  {
    name: component_45stub75NTVOuqo4Meta?.name ?? undefined,
    path: component_45stub75NTVOuqo4Meta?.path ?? "/contact-us/ask-a-question/thank-you/",
    meta: component_45stub75NTVOuqo4Meta || {},
    alias: component_45stub75NTVOuqo4Meta?.alias || [],
    redirect: component_45stub75NTVOuqo4Meta?.redirect,
    component: component_45stub75NTVOuqo4
  },
  {
    name: component_45stub75NTVOuqo4Meta?.name ?? undefined,
    path: component_45stub75NTVOuqo4Meta?.path ?? "/contact-us/warranty-services/",
    meta: component_45stub75NTVOuqo4Meta || {},
    alias: component_45stub75NTVOuqo4Meta?.alias || [],
    redirect: component_45stub75NTVOuqo4Meta?.redirect,
    component: component_45stub75NTVOuqo4
  },
  {
    name: component_45stub75NTVOuqo4Meta?.name ?? undefined,
    path: component_45stub75NTVOuqo4Meta?.path ?? "/email-signup/",
    meta: component_45stub75NTVOuqo4Meta || {},
    alias: component_45stub75NTVOuqo4Meta?.alias || [],
    redirect: component_45stub75NTVOuqo4Meta?.redirect,
    component: component_45stub75NTVOuqo4
  },
  {
    name: component_45stub75NTVOuqo4Meta?.name ?? undefined,
    path: component_45stub75NTVOuqo4Meta?.path ?? "/get-updates/",
    meta: component_45stub75NTVOuqo4Meta || {},
    alias: component_45stub75NTVOuqo4Meta?.alias || [],
    redirect: component_45stub75NTVOuqo4Meta?.redirect,
    component: component_45stub75NTVOuqo4
  },
  {
    name: component_45stub75NTVOuqo4Meta?.name ?? undefined,
    path: component_45stub75NTVOuqo4Meta?.path ?? "/get-updates/thank-you/",
    meta: component_45stub75NTVOuqo4Meta || {},
    alias: component_45stub75NTVOuqo4Meta?.alias || [],
    redirect: component_45stub75NTVOuqo4Meta?.redirect,
    component: component_45stub75NTVOuqo4
  },
  {
    name: component_45stub75NTVOuqo4Meta?.name ?? undefined,
    path: component_45stub75NTVOuqo4Meta?.path ?? "/how-to-video-library/",
    meta: component_45stub75NTVOuqo4Meta || {},
    alias: component_45stub75NTVOuqo4Meta?.alias || [],
    redirect: component_45stub75NTVOuqo4Meta?.redirect,
    component: component_45stub75NTVOuqo4
  },
  {
    name: component_45stub75NTVOuqo4Meta?.name ?? undefined,
    path: component_45stub75NTVOuqo4Meta?.path ?? "/lifestyle/",
    meta: component_45stub75NTVOuqo4Meta || {},
    alias: component_45stub75NTVOuqo4Meta?.alias || [],
    redirect: component_45stub75NTVOuqo4Meta?.redirect,
    component: component_45stub75NTVOuqo4
  },
  {
    name: component_45stub75NTVOuqo4Meta?.name ?? undefined,
    path: component_45stub75NTVOuqo4Meta?.path ?? "/locations/lanterns-at-rock-creek/",
    meta: component_45stub75NTVOuqo4Meta || {},
    alias: component_45stub75NTVOuqo4Meta?.alias || [],
    redirect: component_45stub75NTVOuqo4Meta?.redirect,
    component: component_45stub75NTVOuqo4
  },
  {
    name: component_45stub75NTVOuqo4Meta?.name ?? undefined,
    path: component_45stub75NTVOuqo4Meta?.path ?? "/locations/silver-leaf/",
    meta: component_45stub75NTVOuqo4Meta || {},
    alias: component_45stub75NTVOuqo4Meta?.alias || [],
    redirect: component_45stub75NTVOuqo4Meta?.redirect,
    component: component_45stub75NTVOuqo4
  },
  {
    name: component_45stub75NTVOuqo4Meta?.name ?? undefined,
    path: component_45stub75NTVOuqo4Meta?.path ?? "/locations/wee-cottage-at-north-end-80238/",
    meta: component_45stub75NTVOuqo4Meta || {},
    alias: component_45stub75NTVOuqo4Meta?.alias || [],
    redirect: component_45stub75NTVOuqo4Meta?.redirect,
    component: component_45stub75NTVOuqo4
  },
  {
    name: component_45stub75NTVOuqo4Meta?.name ?? undefined,
    path: component_45stub75NTVOuqo4Meta?.path ?? "/locations/wee-cottage-at-rogers-farm/",
    meta: component_45stub75NTVOuqo4Meta || {},
    alias: component_45stub75NTVOuqo4Meta?.alias || [],
    redirect: component_45stub75NTVOuqo4Meta?.redirect,
    component: component_45stub75NTVOuqo4
  },
  {
    name: component_45stub75NTVOuqo4Meta?.name ?? undefined,
    path: component_45stub75NTVOuqo4Meta?.path ?? "/locations/west-edge-at-colliers-hill/",
    meta: component_45stub75NTVOuqo4Meta || {},
    alias: component_45stub75NTVOuqo4Meta?.alias || [],
    redirect: component_45stub75NTVOuqo4Meta?.redirect,
    component: component_45stub75NTVOuqo4
  },
  {
    name: component_45stub75NTVOuqo4Meta?.name ?? undefined,
    path: component_45stub75NTVOuqo4Meta?.path ?? "/login/",
    meta: component_45stub75NTVOuqo4Meta || {},
    alias: component_45stub75NTVOuqo4Meta?.alias || [],
    redirect: component_45stub75NTVOuqo4Meta?.redirect,
    component: component_45stub75NTVOuqo4
  },
  {
    name: component_45stub75NTVOuqo4Meta?.name ?? undefined,
    path: component_45stub75NTVOuqo4Meta?.path ?? "/lost-password/",
    meta: component_45stub75NTVOuqo4Meta || {},
    alias: component_45stub75NTVOuqo4Meta?.alias || [],
    redirect: component_45stub75NTVOuqo4Meta?.redirect,
    component: component_45stub75NTVOuqo4
  },
  {
    name: component_45stub75NTVOuqo4Meta?.name ?? undefined,
    path: component_45stub75NTVOuqo4Meta?.path ?? "/maintenance-items/",
    meta: component_45stub75NTVOuqo4Meta || {},
    alias: component_45stub75NTVOuqo4Meta?.alias || [],
    redirect: component_45stub75NTVOuqo4Meta?.redirect,
    component: component_45stub75NTVOuqo4
  },
  {
    name: component_45stub75NTVOuqo4Meta?.name ?? undefined,
    path: component_45stub75NTVOuqo4Meta?.path ?? "/marshall-fire-response/thank-you-loi/",
    meta: component_45stub75NTVOuqo4Meta || {},
    alias: component_45stub75NTVOuqo4Meta?.alias || [],
    redirect: component_45stub75NTVOuqo4Meta?.redirect,
    component: component_45stub75NTVOuqo4
  },
  {
    name: component_45stub75NTVOuqo4Meta?.name ?? undefined,
    path: component_45stub75NTVOuqo4Meta?.path ?? "/neighborhoods-map-test/",
    meta: component_45stub75NTVOuqo4Meta || {},
    alias: component_45stub75NTVOuqo4Meta?.alias || [],
    redirect: component_45stub75NTVOuqo4Meta?.redirect,
    component: component_45stub75NTVOuqo4
  },
  {
    name: component_45stub75NTVOuqo4Meta?.name ?? undefined,
    path: component_45stub75NTVOuqo4Meta?.path ?? "/organism-page/",
    meta: component_45stub75NTVOuqo4Meta || {},
    alias: component_45stub75NTVOuqo4Meta?.alias || [],
    redirect: component_45stub75NTVOuqo4Meta?.redirect,
    component: component_45stub75NTVOuqo4
  },
  {
    name: component_45stub75NTVOuqo4Meta?.name ?? undefined,
    path: component_45stub75NTVOuqo4Meta?.path ?? "/our-homes/home-collections/",
    meta: component_45stub75NTVOuqo4Meta || {},
    alias: component_45stub75NTVOuqo4Meta?.alias || [],
    redirect: component_45stub75NTVOuqo4Meta?.redirect,
    component: component_45stub75NTVOuqo4
  },
  {
    name: component_45stub75NTVOuqo4Meta?.name ?? undefined,
    path: component_45stub75NTVOuqo4Meta?.path ?? "/our-homes/home-collections/lanterns-series/",
    meta: component_45stub75NTVOuqo4Meta || {},
    alias: component_45stub75NTVOuqo4Meta?.alias || [],
    redirect: component_45stub75NTVOuqo4Meta?.redirect,
    component: component_45stub75NTVOuqo4
  },
  {
    name: component_45stub75NTVOuqo4Meta?.name ?? undefined,
    path: component_45stub75NTVOuqo4Meta?.path ?? "/our-homes/home-collections/opportunities-series/",
    meta: component_45stub75NTVOuqo4Meta || {},
    alias: component_45stub75NTVOuqo4Meta?.alias || [],
    redirect: component_45stub75NTVOuqo4Meta?.redirect,
    component: component_45stub75NTVOuqo4
  },
  {
    name: component_45stub75NTVOuqo4Meta?.name ?? undefined,
    path: component_45stub75NTVOuqo4Meta?.path ?? "/our-homes/photo-gallery/",
    meta: component_45stub75NTVOuqo4Meta || {},
    alias: component_45stub75NTVOuqo4Meta?.alias || [],
    redirect: component_45stub75NTVOuqo4Meta?.redirect,
    component: component_45stub75NTVOuqo4
  },
  {
    name: component_45stub75NTVOuqo4Meta?.name ?? undefined,
    path: component_45stub75NTVOuqo4Meta?.path ?? "/our-homes/photo-gallery/gourmet-kitchens/",
    meta: component_45stub75NTVOuqo4Meta || {},
    alias: component_45stub75NTVOuqo4Meta?.alias || [],
    redirect: component_45stub75NTVOuqo4Meta?.redirect,
    component: component_45stub75NTVOuqo4
  },
  {
    name: component_45stub75NTVOuqo4Meta?.name ?? undefined,
    path: component_45stub75NTVOuqo4Meta?.path ?? "/our-homes/photo-gallery/home-offices/",
    meta: component_45stub75NTVOuqo4Meta || {},
    alias: component_45stub75NTVOuqo4Meta?.alias || [],
    redirect: component_45stub75NTVOuqo4Meta?.redirect,
    component: component_45stub75NTVOuqo4
  },
  {
    name: component_45stub75NTVOuqo4Meta?.name ?? undefined,
    path: component_45stub75NTVOuqo4Meta?.path ?? "/our-homes/photo-gallery/living-rooms/",
    meta: component_45stub75NTVOuqo4Meta || {},
    alias: component_45stub75NTVOuqo4Meta?.alias || [],
    redirect: component_45stub75NTVOuqo4Meta?.redirect,
    component: component_45stub75NTVOuqo4
  },
  {
    name: component_45stub75NTVOuqo4Meta?.name ?? undefined,
    path: component_45stub75NTVOuqo4Meta?.path ?? "/our-homes/photo-gallery/outdoor-living-spaces/",
    meta: component_45stub75NTVOuqo4Meta || {},
    alias: component_45stub75NTVOuqo4Meta?.alias || [],
    redirect: component_45stub75NTVOuqo4Meta?.redirect,
    component: component_45stub75NTVOuqo4
  },
  {
    name: component_45stub75NTVOuqo4Meta?.name ?? undefined,
    path: component_45stub75NTVOuqo4Meta?.path ?? "/our-homes/photo-gallery/relaxing-master-suites/",
    meta: component_45stub75NTVOuqo4Meta || {},
    alias: component_45stub75NTVOuqo4Meta?.alias || [],
    redirect: component_45stub75NTVOuqo4Meta?.redirect,
    component: component_45stub75NTVOuqo4
  },
  {
    name: component_45stub75NTVOuqo4Meta?.name ?? undefined,
    path: component_45stub75NTVOuqo4Meta?.path ?? "/register/",
    meta: component_45stub75NTVOuqo4Meta || {},
    alias: component_45stub75NTVOuqo4Meta?.alias || [],
    redirect: component_45stub75NTVOuqo4Meta?.redirect,
    component: component_45stub75NTVOuqo4
  },
  {
    name: component_45stub75NTVOuqo4Meta?.name ?? undefined,
    path: component_45stub75NTVOuqo4Meta?.path ?? "/reset-password/",
    meta: component_45stub75NTVOuqo4Meta || {},
    alias: component_45stub75NTVOuqo4Meta?.alias || [],
    redirect: component_45stub75NTVOuqo4Meta?.redirect,
    component: component_45stub75NTVOuqo4
  },
  {
    name: component_45stub75NTVOuqo4Meta?.name ?? undefined,
    path: component_45stub75NTVOuqo4Meta?.path ?? "/resources-lower-maintenance/",
    meta: component_45stub75NTVOuqo4Meta || {},
    alias: component_45stub75NTVOuqo4Meta?.alias || [],
    redirect: component_45stub75NTVOuqo4Meta?.redirect,
    component: component_45stub75NTVOuqo4
  },
  {
    name: component_45stub75NTVOuqo4Meta?.name ?? undefined,
    path: component_45stub75NTVOuqo4Meta?.path ?? "/style-guide/",
    meta: component_45stub75NTVOuqo4Meta || {},
    alias: component_45stub75NTVOuqo4Meta?.alias || [],
    redirect: component_45stub75NTVOuqo4Meta?.redirect,
    component: component_45stub75NTVOuqo4
  },
  {
    name: component_45stub75NTVOuqo4Meta?.name ?? undefined,
    path: component_45stub75NTVOuqo4Meta?.path ?? "/warranty-service-request/",
    meta: component_45stub75NTVOuqo4Meta || {},
    alias: component_45stub75NTVOuqo4Meta?.alias || [],
    redirect: component_45stub75NTVOuqo4Meta?.redirect,
    component: component_45stub75NTVOuqo4
  }
]